import React, { FC } from "react";
import "./modal.css"; // Import CSS for styling modal (create this file)
import "font-awesome/css/font-awesome.min.css";
import { strings } from "../../utils/strings.tsx";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: any;
  isAmenitiesListPresent?: boolean;
  amenitiesList?: string[];
  isServicesListPresent?: boolean;
  servicesList?: string[];
}

const Modal: FC<ModalProps> = ({
  isOpen,
  title,
  onClose,
  isAmenitiesListPresent = false,
  amenitiesList,
  isServicesListPresent = false,
  servicesList,
}) => {
  if (!isOpen) return null;

  const handleBackdropClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const handleContainerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="Modal-Backdrop" onClick={handleBackdropClick}>
      <div className="Modal-Container" onClick={handleContainerClick}>
        <div className="Modal-Header">
          <text className="Header-Text">{title}</text>
          <button className="Modal-Close-Button" onClick={onClose}>
            <i className="fa fa-times" />
          </button>
        </div>
        <hr />
        {isAmenitiesListPresent == true ? (
          <div className="Amenities-List">
            {amenitiesList?.map((item, index) => (
              <div key={index} className="Amenity-Item">
                {strings?.[item] || item}
              </div>
            ))}
          </div>
        ) : isServicesListPresent == true ? (
          <div className="Amenities-List">
            {servicesList?.map((item, index) => (
              <div key={index} className="Amenity-Item">
                {strings?.[item] || item}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
