// Function to make a GET request to the API
const bearerToken =
  "Bearer " +
  "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyMDIiLCJmaXJzdE5hbWUiOiJBc2hpc2giLCJvdHBsZXNzVG9rZW4iOiJhYmNkMTIzNCIsImV4cCI6MTkxNTE4NTEwMH0.tX8Ba3Zu4HJM7ivpkKTMPwvwwcgSQrVosQHY6KQt9YU";

export const getData = async (url) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: bearerToken,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(`${responseData.error?.message || responseData.detail}`);
    }
    return responseData;
  } catch (error) {
    throw new Error(`${error.message}`);
  }
};
