import React from "react";
import "./propertyTile.css";

interface PropertyTileProps {
  propertyName?: string;
  managerName?: string;
  contactNumber?: string;
  textButtonVisible?: boolean;
  textButtonIconVisible?: boolean;
  textButtonIcon?: React.ReactNode;
  textButtonText?: string;
  onTextButtonPress?: any;
}

const PropertyTile: React.FC<PropertyTileProps> = ({
  propertyName,
  managerName,
  contactNumber,
  textButtonVisible = true,
  textButtonIconVisible,
  textButtonIcon,
  textButtonText,
  onTextButtonPress,
}) => {
  return (
    <div className="Main">
      <div className="Property-Details-Section">
        <text className="Property-Text">{propertyName}</text>
        {textButtonVisible && (
          <button className="Text-Button">
            <text className="Text-Button-Text">{textButtonText}</text>
          </button>
        )}
      </div>
      <hr />
      <text className="Manager-Label">Property Manager</text>
      <div className="Manager-Details">
        <text className="Manager-Name">{managerName} - </text>
        <text className="Manager-Contact">{contactNumber}</text>
      </div>
    </div>
  );
};

export default PropertyTile;
