export const strings = {
  SINGLE_SHARING: "Single Sharing",
  DOUBLE_SHARING: "Double Sharing",
  TRIPLE_SHARING: "Triple Sharing",
  FOUR_SHARING: "Four Sharing",
  FIVE_SHARING: "Five Sharing",

  AC: "AC",
  AIR_CONDITIONING: "AC",
  FRIDGE: "Refrigerator",
  REFRIGERATOR: "Refrigerator",
  TABLE: "Table",
  CHAIR: "Chair",
  TELEVISION: "Television",
  COOLER: "Cooler",
  FAN: "Fan",
  CUPBOARD: "Cupboard",
  BED_MATTRESS: "Bed with mattress",
  GEYSER: "Geyser",
  BALCONY: "Balcony",
  BEDSHEETS_PILLOW: "Bedsheets & pillow",
  BLANKET: "Blanket",
  BUCKET_MUG: "Bucket & mug",
  KITCHEN_AREA: "Kitchen area",
  INDUCTION_COOKTOP: "Induction cooktop",
  BOOKSHELF: "Bookshelf",
  MICROWAVE: "Microwave",
  MOSQUITO_REPELLENT: "Mosquitoes repellent",

  WIFI: "WiFi",
  HOUSEKEEPING: "Housekeeping",
  GYM: "Gym",
  LAUNDRY: "Laundry",
  PEST_CONTROL: "Pest control",
  WASHING_MACHINE: "Washing machine",
  TWO_WHEELER_PARKING: "Two-wheeler parking",
  FOUR_WHEELER_PARKING: "Four-wheeler parking",
  SWIMMING_POOL: "Swimming pool",
  SNOOKER_TABLE: "Snooker table",
  FOOSBALL_TABLE: "Foosball table",
  PING_PONG_TABLE: "Ping-pong table",
  CARROM_BOARD: "Carrom board",
  CCTV: "CCTV",
  TWENTY_FOUR_SEVEN_SECURITY: "24x7 security",
  WATER_COOLER: "Water cooler",
  COFFEE_MACHINE: "Coffee machine",
  COMMON_REFGRIGERATOR: "Common refrigerator",
  COMMON_MICROWAVE: "Common microwave",
  VENDING_MACHINE: "Vending machine",
};
