import React from "react";
import "./roomCard.css";

interface RoomCardProps {
  cardSubHeading: string;
  roomCategory: string;
  textButton1?: string;
  onTextButton1Press?: () => void;
  textButton2?: string;
  onTextButton2Press?: any;
  trailingText1?: string;
  trailingText2?: string;
}

const RoomCard: React.FC<RoomCardProps> = ({
  cardSubHeading,
  roomCategory,
  textButton1,
  textButton2,
  onTextButton1Press,
  onTextButton2Press,
  trailingText1,
  trailingText2,
}) => {
  return (
    <>
      <div className="Main-Container">
        <text className="Sub-Heading-Text">{cardSubHeading}</text>
        <div style={{ paddingTop: "4px" }} />
        <text className="Room-Category-Text">{roomCategory}</text>
        <div style={{ paddingTop: "12px" }} className="Text-Button-Container">
          <button className="Text-Button" onClick={onTextButton1Press}>
            <text className="Text-Button-Text">{textButton1}</text>
          </button>
          {textButton2 && (
            <button className="Text-Button" onClick={onTextButton2Press}>
              <text className="Text-Button-Text">{textButton2}</text>
            </button>
          )}
        </div>
        <div style={{ paddingTop: "4px" }} className="Trailing-Text-Container">
          <text className="Trailing-Text">{trailingText1}</text>
          <text className="Trailing-Text">{trailingText2}</text>
        </div>
      </div>
      <div style={{ paddingTop: "16px" }} />
    </>
  );
};

export default RoomCard;
