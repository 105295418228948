import React, { FC, useEffect, useState } from "react";
import "./propertyProfile.css";
import { Modal, PropertyTile, RoomCard } from "../../components/index.tsx";
import { getData } from "../../apiUtil";
import { apiUrl } from "../../config.js";
import { strings } from "../../utils/strings.tsx";

type PropertyProfileType = {
  orgName: string;
  branchName: string;
  managerName: string;
  managerContactNumber: string;
  branchCompleteAddress: string;
  inventory: InventoryType[];
  services: string[];
};

type InventoryType = {
  roomSharingType: string;
  rentAmount: number;
  securityAmount: number;
  roomCount: number;
  amenitiesCount: number;
  amenitiesList: string[];
};

const getQueryParam = (name) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(name);
};

const PropertyProfile = () => {
  const [data, setData] = useState<PropertyProfileType>();

  const [isAmenitiesModalOpen, setIsAmenitiesModalOpen] = useState(false);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const propertyDetails = {
    propertyName: "D-E-M-O Hostels",
    propertyAddress:
      "Building 2, Street 13, Sector 48, Gurugram, HARYANA, India, 122011",
    managerName: "Ram Kishan",
    managerContactNumber: "9908769876",
  };

  useEffect(() => {
    const branchId = getQueryParam("branchId");
    // getData(apiUrl + "/setting/business-profile?branchId=" + 179)
    getData(apiUrl + `/business-profile?branchId=${branchId}`)
      .then((data) => {
        setData(data);
        console.log("Data : ", data);
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  }, []);

  const handleAmenitiesClick = (index) => {
    setIsAmenitiesModalOpen(true);
    setSelectedIndex(index);
    // console.log("handleAmenitiesClick = ", isAmenitiesModalOpen);
    // return <RenderModal />;
    console.log("index == ", index);
  };

  const handleCloseAmenitiesPopup = () => {
    setIsAmenitiesModalOpen(false);
    setSelectedIndex(null);
  };

  const RenderAmenitiesModal = ({ index }) => {
    if (!isAmenitiesModalOpen) return null;
    console.log("index == ", index);
    return (
      <Modal
        isOpen={isAmenitiesModalOpen}
        onClose={handleCloseAmenitiesPopup}
        title={"Room Amenities"}
        isAmenitiesListPresent={true}
        amenitiesList={data?.inventory[index]?.amenitiesList || []}
      />
    );
  };

  const handleServicesClick = () => {
    setIsServicesModalOpen(true);
  };

  const handleCloseServicesPopup = () => {
    setIsServicesModalOpen(false);
  };

  const RenderServicesModal = () => {
    if (!isServicesModalOpen) return null;
    return (
      <Modal
        isOpen={isServicesModalOpen}
        onClose={handleCloseServicesPopup}
        title={"Property Services & Amenities"}
        isServicesListPresent={true}
        servicesList={data?.services || []}
      />
    );
  };

  return (
    <>
      <div className="Background">
        <div className="App">
          <header className="Header">
            <text className="Header-Text">{data?.orgName}</text>
          </header>
          <div className="Body">
            <div style={{ paddingTop: "16px" }} />
            <PropertyTile
              propertyName={data?.branchName}
              managerName={data?.managerName}
              textButtonText="Share"
              textButtonVisible={false}
              contactNumber={data?.managerContactNumber}
            />
            <div style={{ paddingTop: "16px" }} />
            <div className="Address-Container">
              <div className="Address-Details">
                <div className="Location-Icon">
                  <img
                    src={require("../../assets/images/location-icon.png")}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <text className="Address-Text">
                  {data?.branchCompleteAddress}
                </text>
              </div>
              <button className="View-On-Map" onClick={() => {}}>
                View on map
              </button>
            </div>
            <hr />
            <div className="Room-Card-Section">
              <text className="Room-Details-Text">ROOM DETAILS</text>
              <div style={{ paddingTop: "16px" }} />
              {data?.inventory.map((inv, index) => {
                return (
                  <RoomCard
                    cardSubHeading={`Option ${index + 1}`}
                    roomCategory={
                      strings?.[inv.roomSharingType] || inv.roomSharingType
                    }
                    textButton1={`${inv.amenitiesCount} amenities`}
                    onTextButton1Press={() => handleAmenitiesClick(index)}
                    trailingText1={`Rent : ₹${inv.rentAmount}`}
                    trailingText2={`Security : ₹${inv.securityAmount}`}
                  />
                );
              })}
            </div>
            <hr />
            <div className="Services-Section">
              <text className="Room-Details-Text">SERVICES</text>
              <div
                style={{ paddingTop: "4px" }}
                className="Services-Button-Section"
              >
                <text className="Services-Text">
                  {data?.services.length} Services & Amenities
                </text>
                <button
                  className="Text-Button"
                  onClick={() => {
                    handleServicesClick();
                  }}
                >
                  <text className="View-List-Button">View list</text>
                </button>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="Bottom-Container">
              <text className="Footer-Text">Made with ❤️ by StayFLO</text>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <RenderAmenitiesModal index={selectedIndex} />
      </div>
      <div style={{ position: "relative" }}>
        <RenderServicesModal />
      </div>
    </>
  );
};

export default PropertyProfile;
